/** @jsxRuntime classic */
/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, PageProps } from "gatsby"
import { jsx, Box, Heading, Paragraph, Container } from "theme-ui"

import type { NewsSingleQuery } from "../../graphql-types"

import BlogBlockMapper from "components/block-mappers/BlogBlockMapper"
import Layout from "components/Layout"

interface NewsSingleProps extends PageProps {
  data: NewsSingleQuery
}

const NewsSinglePage = ({ data, location }: NewsSingleProps) => {
  const fixedDate = new Date(data.strapiArticle?.Publish_Date)
  const formattedDate = fixedDate.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Layout seo={data.strapiArticle?.seo} location={location}>
      <Box as="article" sx={{ my: 28 }}>
        <Container variant="layout.narrow" sx={{ mb: [8, null, null, 12] }}>
          <Paragraph
            as="time"
            variant="text.bodyTitleThin"
            sx={{ display: "block", color: "primary", opacity: 1, mb: 5 }}
          >
            {formattedDate}
          </Paragraph>
          <Heading as="h1" sx={{ mb: 5 }}>
            {data.strapiArticle?.title}
          </Heading>
          {data.strapiArticle?.featured_image && (
            <GatsbyImage
              image={
                data.strapiArticle.featured_image.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={
                (data.strapiArticle.featured_image.alternativeText as string) ||
                ""
              }
              objectFit="cover"
              sx={{ width: "100%", height: "auto" }}
            />
          )}
        </Container>

        {data.strapiArticle?.Dynamic_Zone?.map((block: any, index: number) => (
          <BlogBlockMapper block={block} key={index} />
        ))}
      </Box>
    </Layout>
  )
}

export default NewsSinglePage

export const pageQuery = graphql`
  query NewsSingle($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      Publish_Date
      slug
      Dynamic_Zone
      title
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      featured_image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
