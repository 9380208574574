/** @jsx jsx */
import SwiperCore, { Navigation } from "swiper"
import { Box, Heading, jsx } from "theme-ui"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/navigation"

SwiperCore.use([Navigation])

export type GalleryImage = {
  image: IGatsbyImageData | string
  imageAlt: string
}

interface PhotoGallerySliderProps {
  //enables sx prop to be passed down from parent
  className?: string
  images: GalleryImage[]
}

const PhotoGallerySlider = ({ className, images }: PhotoGallerySliderProps) => {
  return (
    <Box sx={{ my: 16, maxWidth: "1120px", mx: "auto" }}>
      <Heading as="h2" sx={{ mb: 8 }}>
        Photo Gallery
      </Heading>
      <Box
        sx={{
          position: "relative",
          px: 10,
          "& .swiper-button-prev:after, & .swiper-button-next:after": {
            color: "primary",
          },
          "& .swiper-button-prev": {
            left: 0,
          },
          "& .swiper-button-next": {
            right: 0,
          },
        }}
      >
        <Box className="swiper-button-prev"></Box>
        <Swiper
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          breakpoints={{
            "360": {
              slidesPerView: 1,
            },
            "480": {
              slidesPerView: 2,
            },
            "698": {
              slidesPerView: 3,
            },
            "960": {
              slidesPerView: 4,
            },
          }}
          sx={{
            height: "240px",
            maxWidth: "996px",
          }}
        >
          {images.map(({ image, imageAlt }, index) => (
            <SwiperSlide key={index}>
              <img
                src={image as string}
                alt={imageAlt}
                sx={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  verticalAlign: "middle",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box className="swiper-button-next" />
      </Box>
    </Box>
  )
}

export default PhotoGallerySlider
