/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import ReactPlayer from "react-player"
import { jsx, Container, Divider, AspectRatio } from "theme-ui"

import { getStrapiCorrectImageSrc } from "helpers/index"

import MdContent from "components/common/MdContent"
import PhotoGallerySlider, { GalleryImage } from "components/PhotoGallerySlider"

interface BlogBlockMapperProps {
  block: any
}

const BlogBlockMapper = ({ block }: BlogBlockMapperProps) => {
  switch (block.strapi_component) {
    case "blog-components.paragraph":
      return (
        <Container variant="narrow" sx={{ mb: [6, null, null, 10] }}>
          <MdContent
            source={block.text}
            sx={{
              img: {
                height: "100%",
                maxHeight: "355px",
                my: 14,
                objectFit: "cover",
                width: "100%",
              },
            }}
          />
        </Container>
      )
    case "blog-components.hr":
      return (
        <Container variant="wide">
          <Divider sx={{ my: [12, null, null, 16] }} />
        </Container>
      )
    case "blog-components.image-gallery":
      let galleryImages: GalleryImage[] = []

      if (block.gallery) {
        for (let i = 0; i < block.gallery.length; i++) {
          const photo = block.gallery[i]

          galleryImages.push({
            imageAlt: photo?.alternativeText as string,
            image: getStrapiCorrectImageSrc(photo.url),
          })
        }
      }

      return (
        <Container>
          <PhotoGallerySlider images={galleryImages} />
        </Container>
      )
    case "blog-components.video":
      return (
        <Container
          sx={{
            my: 12,
            position: "relative",
          }}
        >
          <AspectRatio ratio={16 / 9}>
            <ReactPlayer
              className="react-player"
              url={block.URL}
              width="100%"
              height="100%"
              sx={{
                left: 0,
                position: "absolute",
                top: 0,
              }}
            />
          </AspectRatio>
        </Container>
      )
    default:
      return <></>
  }
}

export default BlogBlockMapper
